import { type QueryKey } from '@tanstack/react-query'
import { type NoteType, isCalendarNote, isTeamspaceNote } from './syncUtils'

// privateKeys
// 0: private, calendar, $filename
// 7: private, calendar, $filename
// 1: private, project, $recordName
// 5: private, project, $recordName
// references: private, references
// logincheck: private, logincheck
export const privateKeys = {
  0: (filename: string) =>
    ['private', 'notes', 'calendar', filename] as QueryKey,
  7: (filename: string) =>
    ['private', 'notes', 'calendar', filename] as QueryKey,
  1: (recordName: string) =>
    ['private', 'notes', 'project', recordName] as QueryKey,
  5: (recordName: string) =>
    ['private', 'notes', 'project', recordName] as QueryKey,
  notes: ['private', 'notes'] as QueryKey,
  references: ['private', 'references'] as QueryKey,
  logincheck: ['private', 'logincheck'] as QueryKey,
}
// teamKeys
// 10: team, $teamspaceId
// members: team, $teamspaceId, members
// 12: team, $teamspaceId, calendar, $filename
// 11: team, project, $recordName
// references: team, references
export const teamKeys = {
  10: (teamspaceId: string) => ['team', teamspaceId] as QueryKey,
  members: (teamspaceId: string) =>
    ['team', teamspaceId, 'members'] as QueryKey,
  12: (teamspaceId: string, filename: string) =>
    ['team', teamspaceId, 'calendar', filename] as QueryKey,
  11: (recordName: string) => ['team', 'project', recordName] as QueryKey,
  all: ['team'] as QueryKey,
  references: ['team', 'references'] as QueryKey,
}

// cacheKeys
// extension: cache, extension
// notes: cache, notes
// privateNotes: cache, notes, private, $userId
// privateCalendarNotes: cache, notes, private, $userId, calendar
// privateProjectNotes: cache, notes, private, $userId, project
// teamNotes: cache, notes, team, $userId
// teamCalendarNotes: cache, notes, team, $userId, calendar
// teamProjectNotes: cache, notes, team, $userId, project
// appleCalendars: cache, calendars, apple, $userId
// appleEvents: cache, calendar, apple, $userId, $url, $date
export const cacheKeys = {
  extension: ['cache', 'extension'] as QueryKey,
  notes: ['cache', 'notes'] as QueryKey,
  privateNotes: (userId: string | null = null) =>
    userId
      ? (['cache', 'notes', 'private', userId] as QueryKey)
      : (['cache', 'notes', 'private'] as QueryKey),
  privateCalendarNotes: (userId: string) =>
    ['cache', 'notes', 'private', userId, 'calendar'] as QueryKey,
  privateProjectNotes: (userId: string) =>
    ['cache', 'notes', 'private', userId, 'project'] as QueryKey,
  teamNotes: (userId: string | null = null) =>
    userId
      ? (['cache', 'notes', 'team', userId] as QueryKey)
      : (['cache', 'notes', 'team'] as QueryKey),
  teamCalendarNotes: (userId: string) =>
    ['cache', 'notes', 'team', userId, 'calendar'] as QueryKey,
  teamProjectNotes: (userId: string) =>
    ['cache', 'notes', 'team', userId, 'project'] as QueryKey,
  appleCalendars: (userId: string) =>
    ['cache', 'calendars', 'apple', userId] as QueryKey,
  appleEvents: (userId: string, url: string, date: string) =>
    ['cache', 'calendar', 'apple', userId, url, date] as QueryKey,
}

export function noteQueryKey({
  noteType,
  recordName,
  filename,
  parent,
}: {
  noteType: NoteType
  recordName?: string
  filename: string
  parent?: string
}) {
  if (isTeamspaceNote(noteType)) {
    if (isCalendarNote(noteType)) {
      if (!parent) throw new Error('parent is required for team calendar notes')
      if (!filename) throw new Error('filename is required for calendar notes')
      return teamKeys[noteType](parent, filename)
    }
    if (!recordName) throw new Error('recordName is required for project notes')
    return teamKeys[noteType](recordName)
  }
  if (isCalendarNote(noteType)) {
    if (!filename) throw new Error('filename is required for calendar notes')
    return privateKeys[noteType](filename)
  }
  if (!recordName) throw new Error('recordName is required for project notes')
  return privateKeys[noteType](recordName)
}

export function projectNoteQueryKey({
  recordName,
  noteType,
}: {
  recordName: string
  noteType: NoteType
}) {
  if (isTeamspaceNote(noteType)) {
    return teamKeys[noteType](recordName)
  }
  return privateKeys[noteType](recordName)
}
