import { Menu, MenuItem, MenuButton, MenuHeader } from '@szhsin/react-menu'
import { useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import {
  useAccessToken,
  useSignIn,
  useSignOut,
} from '../../providers/AccessTokenProvider'
import { useAppleCalendars } from '../../providers/CachedNotesProvider'
import { SignInAppleDialog } from './SignInAppleDialog'
import { useNotification } from '../../providers/NotificationProvider'
import { useAppleCalDAVSignout } from '../../hooks/AppleCalDAV'
import { mainId, useUserState } from '../../providers/UserProvider'

export function CalendarMenu() {
  const user = useUserState()
  const userId = mainId(user)
  const errorIcon = 'fa-triangle-exclamation text-red-500'
  const { data: accessToken } = useAccessToken()
  const signin = useSignIn()
  const googleSignin = useGoogleLogin({
    // important config for google identity service to work with our backend
    flow: 'auth-code',
    redirect_uri: 'postmessage', // https://stackoverflow.com/a/18990247
    ux_mode: 'popup',
    scope:
      'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',
    onSuccess: ({ code }) => {
      if (userId) {
        signin.mutate(
          { code, userId },
          {
            onSuccess: () => {
              showNotification({
                title: 'Google Calendar',
                message: 'Sign in successfully',
              })
            },
          }
        )
      } else {
        showNotification({
          title: 'Apple Calendar',
          message: 'User ID is undefined',
          icon: errorIcon,
        })
      }
    }, // sign in was successful, let's store the refresh token and retrieve the access token
    onError: ({ error_description }) => {
      showNotification({
        title: 'Google Calendar',
        message: error_description ?? '',
        icon: errorIcon,
      })
      handleGoogleSignout()
    },
  })
  const googleSignout = useSignOut()

  function handleGoogleSignin() {
    if (userId) {
      googleSignin()
    } else {
      showNotification({
        title: 'Apple Calendar',
        message: 'User ID is undefined',
        icon: errorIcon,
      })
    }
  }

  function handleGoogleSignout() {
    if (userId) {
      googleSignout.mutate(
        { userid: userId },
        {
          onSuccess: () => {
            showNotification({
              title: 'Google Calendar',
              message: 'Sign out successfully',
            })
          },
        }
      )
    } else {
      showNotification({
        title: 'Apple Calendar',
        message: 'User ID is undefined',
        icon: errorIcon,
      })
    }
  }

  const [showSignInAppleDialog, setShowSignInAppleDialog] =
    useState<boolean>(false)
  const { data: appleCalendars = [] } = useAppleCalendars()
  const hasAppleCalendars =
    Array.isArray(appleCalendars) && appleCalendars.length > 0
  const signout = useAppleCalDAVSignout()
  const { showNotification } = useNotification()

  function handleAppleSignout() {
    if (userId) {
      signout.mutate(
        { userid: userId },
        {
          onSuccess: () => {
            showNotification({
              title: 'Apple Calendar',
              message: 'Successfully signed out from Apple Calendars',
            })
          },
          onError: (registerError) => {
            showNotification({
              title: 'Apple Calendar',
              message: registerError.message,
              icon: errorIcon,
            })
          },
        }
      )
    } else {
      showNotification({
        title: 'Apple Calendar',
        message: 'User ID is undefined',
        icon: errorIcon,
      })
    }
  }

  // check at the start if the backend is available or still fetching and disable the calendar button
  return (
    <>
      <SignInAppleDialog
        open={showSignInAppleDialog}
        setOpen={setShowSignInAppleDialog}
      />
      <Menu
        className=''
        menuButton={
          <MenuButton className='p-1 hover:bg-gray-500/25'>
            <i
              className='fa-regular fa-calendar h-5 w-5 text-sm'
              aria-hidden='true'
            />
          </MenuButton>
        }
      >
        <MenuHeader>Manage Calendars</MenuHeader>
        {signin.isError ? (
          <MenuItem>{String(signin.error)}</MenuItem>
        ) : undefined}
        {accessToken && accessToken.token ? (
          <MenuItem onClick={handleGoogleSignout}>
            <i
              className='fa-regular fa-sign-out-alt h-5 w-5 text-sm'
              aria-hidden='true'
            />
            Sign out of Google
          </MenuItem>
        ) : (
          <MenuItem onClick={handleGoogleSignin}>
            <i
              className='fa-regular fa-calendar-circle-plus h-5 w-5 text-sm'
              aria-hidden='true'
            />
            Sign in with Google
          </MenuItem>
        )}
        {hasAppleCalendars ? (
          <MenuItem onClick={handleAppleSignout}>
            <i
              className='fa-regular fa-sign-out-alt h-5 w-5 text-sm'
              aria-hidden='true'
            />
            Sign out of Apple
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              setShowSignInAppleDialog(true)
            }}
          >
            <i
              className='fa-regular fa-calendar-circle-plus h-5 w-5 text-sm'
              aria-hidden='true'
            />
            Sign in with Apple
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

// type SettingsMenuProps = { timelineDays: number; onSetTimelineDays: (_days: number) => void };
// const SettingsMenu = ({ timelineDays, onSetTimelineDays }: SettingsMenuProps) => {
//   return (
//     <Menu
//       className=""
//       menuButton={
//         <MenuButton className="p-1 hover:bg-gray-500/25">
//           <i className="fa-regular fa-gear h-5 w-5 text-sm" aria-hidden="true" />
//         </MenuButton>
//       }
//     >
//       <MenuRadioGroup value={timelineDays} onRadioChange={(e) => onSetTimelineDays(e.value)}>
//         <MenuHeader>Timeline</MenuHeader>
//         <MenuItem type="radio" value={1}>
//           1 day
//         </MenuItem>
//         <MenuItem type="radio" value={2}>
//           2 days
//         </MenuItem>
//         <MenuItem type="radio" value={3}>
//           3 days
//         </MenuItem>
//         <MenuItem type="radio" value={4}>
//           4 days
//         </MenuItem>
//         <MenuItem type="radio" value={5}>
//           5 days
//         </MenuItem>
//         <MenuItem type="radio" value={6}>
//           6 days
//         </MenuItem>
//         <MenuItem type="radio" value={7}>
//           7 days
//         </MenuItem>
//       </MenuRadioGroup>
//     </Menu>
//   );
