import { useEffect } from 'react'
import { useCreateNote } from '../hooks/useCreateNote'
import { useSelectedRecordNameDispatch } from '../providers/SelectedRecordNameProvider'
import { v4 as uuid } from 'uuid'
import { NoteType } from '../utils/syncUtils'
import { useIsFetching } from '@tanstack/react-query'
import { useUserState } from '../providers/UserProvider'

export function GoogleMeetSummary() {
  const isFetching = useIsFetching()
  const user = useUserState()
  const setSelectedRecordName = useSelectedRecordNameDispatch()
  const createNoteMutation = useCreateNote((note) => {
    if (note.recordName) {
      setSelectedRecordName(note.recordName)
    }
  })

  useEffect(() => {
    if (user && isFetching === 0) {
      const summary = sessionStorage.getItem('summary')
      if (summary) {
        const title = summary.split('\n')[0].replaceAll('#', '').trim()
        createNoteMutation.mutate({
          recordName: uuid(),
          filename: title,
          content: summary,
          noteType: NoteType.PROJECT_NOTE,
        })
        sessionStorage.removeItem('summary')
      }
    }
  }, [createNoteMutation, user, isFetching])

  return <></>
}
