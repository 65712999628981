import { useEffect, useState } from 'react'
import { BlockNoteEditor } from '@packages/blocknote-core'
import { BlockNoteView, useBlockNote } from '@packages/blocknote-react'
import { useDarkMode } from '../../providers/DarkModeProvider'

export default function ReadOnlyEditor() {
  const isDarkMode = useDarkMode()
  const loadingText = 'Loading...'
  const [summary, setSummary] = useState(
    sessionStorage.getItem('summary') ?? loadingText
  )

  useEffect(() => {
    const handleSummaryChange = () => {
      const currentSummary = sessionStorage.getItem('summary')
      if (currentSummary && currentSummary !== summary) {
        setSummary(currentSummary)
        clearInterval(intervalId)
      }
    }

    window.addEventListener('storage', handleSummaryChange)

    const intervalId = setInterval(handleSummaryChange, 1000)

    return () => {
      window.removeEventListener('storage', handleSummaryChange)
      clearInterval(intervalId)
    }
  }, [summary])

  const editor = useBlockNote({
    theme: isDarkMode ? 'dark' : 'light',
    editable: false,
  })

  useEffect(() => {
    const blocks = BlockNoteEditor.notePlanToBlocks(summary, '')

    editor?.replaceBlocks(editor.topLevelBlocks, blocks)
  }, [editor, summary])

  return (
    <>
      <a
        className='block w-full cursor-pointer text-center bg-orange-100 py-2 text-gray-900 dark:text-gray-900 no-underline'
        href='/'
      >
        Register or sign in to save this summary
      </a>
      <div className='note-body flex flex-col'>
        <div className='editor-container-wrapper'>
          <div className='editor-container'>
            <BlockNoteView editor={editor} />
          </div>
        </div>
      </div>
    </>
  )
}
