import { type Dayjs } from 'dayjs'
import { type UseQueryResult } from '@tanstack/react-query'
import { type CalendarEvent } from '../../CalendarEvent'
import { useGoogleCalendarEvents } from '../../../../hooks/useGoogleCalendarEvents'
import { flatMapCalendarEvents } from '../../../../utils/calendar'
import { useDarkMode } from '../../../../providers/DarkModeProvider'
import { darkenColor } from '../../../../utils/color'
import {
  type ExtendedDAVObject,
  useAppleEvents,
} from '../../../../providers/CachedNotesProvider'

export function AllDayEvents({
  selectedDay,
  labelWidth,
}: {
  selectedDay: Dayjs
  labelWidth: number
}) {
  const isDarkMode = useDarkMode()
  const { data: googleEvents } = useGoogleCalendarEvents(selectedDay)
  const queriesResults = useAppleEvents(selectedDay)
  const appleCalendarEvents = flatMapCalendarEvents(
    queriesResults as UseQueryResult<ExtendedDAVObject[]>[],
    selectedDay
  )
  const allDayEvents: CalendarEvent[] = [
    ...(googleEvents
      ? googleEvents.filter((event): event is CalendarEvent =>
          Boolean(event.allDay)
        )
      : []),
    ...appleCalendarEvents
      .flat()
      .filter(
        (event): event is CalendarEvent =>
          Boolean(event?.allDay) && !event?.endDate.isSame(selectedDay, 'day')
      ),
  ]
  return (
    <div className='flex border-b-2'>
      <div
        className='flex flex-shrink-0 text-xs leading-tight text-gray-400'
        style={{ width: `${labelWidth.toString()}px` }}
      >
        <div className='w-full self-end pb-0.5 pr-2 text-right'>all-day</div>
      </div>
      <div className='grid flex-auto grid-cols-1 grid-rows-1 pt-0.5'>
        {allDayEvents.map((event) => {
          const color =
            event.color && isDarkMode
              ? darkenColor(event.color, 50)
              : event.color

          return (
            <div
              key={event.id}
              className='mb-0.5 truncate rounded-md px-2 text-xs font-semibold text-gray-700 dark:text-gray-300'
              style={{ backgroundColor: color }}
            >
              {event.content}
            </div>
          )
        })}
      </div>
    </div>
  )
}
