import { trackEvent } from '../lib/analytics'
import { useUserState } from '../providers/UserProvider'
import { determineBaseURL } from '../lib/stripe'

export function useManageSubscription() {
  const user = useUserState()
  const baseURL: string = determineBaseURL(window.location.hostname)

  const openManageSubscription = () => {
    if (!user) {
      return
    }

    if (user.cloudKitUserId) {
      trackEvent('WEB - Manage Subscription Clicked', {
        type: 'native',
      })
      window.open(
        `${baseURL}/manage.php?user=native&id=${user.cloudKitUserId}`,
        '_blank'
      )
    } else if (user.supabaseUserId) {
      trackEvent('WEB - Manage Subscription Clicked', {
        type: 'web',
      })
      window.open(
        `${baseURL}/manage.php?user=web&id=${user.supabaseUserId}`,
        '_blank'
      )
    }
  }

  return { openManageSubscription }
}
