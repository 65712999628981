import { useQueryClient } from '@tanstack/react-query'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import {
  type Note,
  NoteType,
  getSupabaseFileExtension,
  isCalendarNote,
  isFolder,
  isTeamspaceNote,
} from '../utils/syncUtils'
import { useUserState } from '../providers/UserProvider'
import {
  updateNote,
  updateNoteReturnFallback,
  useCachedNotesQueryClient,
  useNotesExtension,
} from '../providers/CachedNotesProvider'
import { cacheKeys, noteQueryKey } from '../utils/queryKeyFactory'
import { createNote } from '../lib/supabase/NoteOperations'
import { trackEvent } from '../lib/analytics'
import { useSafeMutation } from './useSafeMutation'

export type CreateOptions = {
  recordName: string
  noteType: NoteType
  parent?: string
  filename: string
  isDir?: boolean
  content?: string
  title?: string
}

function createOptionsToDraft({
  recordName,
  noteType,
  parent,
  filename,
  isDir,
  content = '# ',
  title,
}: CreateOptions): Note {
  const correctedParent =
    parent === 'notes' || parent === 'teamspaces' ? undefined : parent
  return {
    recordName,
    parent: correctedParent,
    noteType,
    isFolder: isDir,
    filename,
    title: title ?? filename,
    content: isDir ? '' : content,
  }
}

function filenameExists(
  privateNotes: Map<string, Note>,
  filename: string,
  recordName: string
): boolean {
  return Array.from(privateNotes.values()).some(
    (note) => note.filename === filename && note.recordName !== recordName
  )
}

function generateFilename(
  privateNotes: Map<string, Note>,
  baseFilename: string,
  recordName: string,
  extension: string,
  number: number
): string {
  // Construct the filename with the current number
  const newFilename =
    number > 0
      ? `${baseFilename} ${String(number)}${extension}`
      : `${baseFilename}${extension}`

  // Check if this filename exists
  if (filenameExists(privateNotes, newFilename, recordName)) {
    // If it does, increment the number and try again
    return generateFilename(
      privateNotes,
      baseFilename,
      recordName,
      extension,
      number + 1
    )
  }

  return newFilename
}

function augmentNoteForCloudKit(
  draft: Note,
  filename: string,
  ext: string,
  privateProjectNotes: Map<string, Note>
) {
  // make filename unique because CloudKit doesn't allow duplicate filenames
  draft.filename = draft.isFolder ? filename : `${filename}.${ext}`

  if (draft.parent) {
    const parentFilename = privateProjectNotes.get(draft.parent)?.filename
    if (parentFilename) {
      // edge case: sometimes the parent filename contains the filename, so we need to remove it
      const folderName = parentFilename.endsWith(`.${ext}`)
        ? parentFilename.substring(0, parentFilename.lastIndexOf('/'))
        : parentFilename
      draft.filename = `${folderName}/${draft.filename}`
    }
  }

  // Regex to extract base filename and extension
  const regex = /^(.*?)(\.[^.]+)?$/
  const matches = regex.exec(draft.filename)
  const baseFilename = matches[1]
  const extension = matches[2] || ''

  draft.filename = generateFilename(
    privateProjectNotes,
    baseFilename,
    draft.recordName,
    extension,
    0
  )
}

export function useCreateNote(successCallback: (_note: Note) => void) {
  const ck = useCloudKitClient()
  const user = useUserState()
  const privateUserId = user?.cloudKitUserId ?? user?.supabaseUserId
  const { data: ext } = useNotesExtension(user)
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()

  return useSafeMutation<Note, Error, CreateOptions, Map<string, Note>>({
    mutationFn: ({
      recordName,
      noteType,
      parent,
      filename = 'Untitled',
      isDir = false,
      content,
      title,
    }: CreateOptions) => {
      const draft: Note = createOptionsToDraft({
        recordName,
        noteType,
        parent,
        filename,
        isDir,
        content,
        title,
      })
      // console.debug('[useCreateNote] creating', draft)

      // Teamspace notes are managed by supabase
      if (
        user?.cloudKitUserId &&
        !isTeamspaceNote(noteType) &&
        privateUserId &&
        ext
      ) {
        const privateNotes = cachedNotesQueryClient?.getQueryData<
          Map<string, Note>
        >(cacheKeys.privateProjectNotes(privateUserId))
        if (!privateNotes) {
          throw new Error('Private notes not found')
        }
        augmentNoteForCloudKit(draft, filename, ext, privateNotes)
        return ck.createNote(user.cloudKitUserId, draft)
      }

      if (user?.supabaseUserId) {
        draft.filename = draft.isFolder
          ? filename
          : `${filename}.${getSupabaseFileExtension()}`
        return createNote(user.supabaseUserId, draft)
      }

      throw new Error('Not signed in')
    },
    onMutate: ({
      recordName,
      noteType,
      parent,
      filename = 'Untitled',
      isDir = false,
      content,
      title,
    }: CreateOptions) => {
      // console.debug(
      //   '[useCreateNote] onMutate',
      //   recordName,
      //   noteType,
      //   parent,
      //   filename,
      //   isDir,
      //   title
      // )

      const newNote: Note = createOptionsToDraft({
        recordName,
        noteType,
        parent,
        filename,
        isDir,
        content,
        title,
      })
      if (
        user?.cloudKitUserId &&
        !isTeamspaceNote(newNote.noteType) &&
        privateUserId &&
        ext
      ) {
        const privateNotes = cachedNotesQueryClient?.getQueryData<
          Map<string, Note>
        >(cacheKeys.privateProjectNotes(privateUserId))
        if (!privateNotes) {
          throw new Error('Private notes not found')
        }
        augmentNoteForCloudKit(newNote, filename, ext, privateNotes)
      }

      const previousNotes = updateNoteReturnFallback({
        currentCachedNotesQueryClient: cachedNotesQueryClient,
        privateUserId,
        teamUserId: user?.supabaseUserId,
        updatedNote: newNote,
      })
      if (!previousNotes) {
        throw new Error('Previous notes not found')
      }

      if (!isFolder(newNote)) {
        queryClient.setQueryData(noteQueryKey(newNote), () => newNote)
        // Only jump right away to the new note if it's a Supabase note
        // CloudKit doesn't have a recordChangeTag yet and an attempt to save the note will fail / create another unwanted note
        if (!user.cloudKitUserId || isTeamspaceNote(newNote.noteType)) {
          successCallback(newNote)
        }
      }

      // return a context object with the snapshotted value
      return previousNotes
    },
    onError: (_error, variables: CreateOptions, context: Map<string, Note>) => {
      const cacheKey = isTeamspaceNote(variables.noteType)
        ? isCalendarNote(variables.noteType)
          ? cacheKeys.teamCalendarNotes(user.supabaseUserId)
          : cacheKeys.teamProjectNotes(user.supabaseUserId)
        : isCalendarNote(variables.noteType)
          ? cacheKeys.privateCalendarNotes(privateUserId)
          : cacheKeys.privateProjectNotes(privateUserId)

      cachedNotesQueryClient.setQueryData(cacheKey, context)
    },
    onSuccess: (newNote: Note) => {
      trackEvent(
        newNote.noteType === NoteType.TEAM_SPACE
          ? 'WEB - Teamspace Created'
          : 'WEB - Note Created',
        {
          recordName: newNote.recordName,
          noteType: NoteType[newNote.noteType],
          parent: newNote.parent,
          filename: newNote.filename,
          content: newNote.content,
          title: newNote.title,
        }
      )

      // update the cache
      updateNote(
        cachedNotesQueryClient,
        privateUserId,
        user.supabaseUserId,
        newNote
      )

      if (!isFolder(newNote)) {
        // set the new note in the cache
        queryClient.setQueryData(noteQueryKey(newNote), () => newNote)
      }

      // We always need to return the callback
      successCallback(newNote)
    },
  })
}
