import { type UseQueryResult } from '@tanstack/react-query'
import { type Dayjs } from 'dayjs'
import { type Dispatch, type SetStateAction } from 'react'
import { getGroupedEvents } from '../utils/getGroupedEvents'
import { calculateOffsetAndHeight } from '../utils/event'
import { useGoogleCalendarEvents } from '../../../../hooks/useGoogleCalendarEvents'
import {
  type ExtendedDAVObject,
  useAppleEvents,
} from '../../../../providers/CachedNotesProvider'
import { flatMapCalendarEvents } from '../../../../utils/calendar'
import { type CalendarEvent } from '../../CalendarEvent'
import { type PlaceholderEvent } from '../types'
import { SingleEvent } from './SingleEvent'
import { MultipleEvents } from './MultipleEvents'

export type CalendarEventsProps = {
  setCalendarEventDrag: Dispatch<SetStateAction<CalendarEvent | null>>
  selectedDay: Dayjs
  currentEvents: CalendarEvent[]
  placeholderEvent: PlaceholderEvent | null
}

export function CalendarEvents({
  setCalendarEventDrag,
  selectedDay,
  currentEvents,
  placeholderEvent,
}: CalendarEventsProps) {
  const parent = document.getElementById('week-columns')
  let segmentHeight = 28
  if (parent) {
    segmentHeight = parent.getBoundingClientRect().height / 97
  }

  const currentEventsToday = currentEvents.filter((e) =>
    e.startDate.isSame(selectedDay, 'day')
  )
  const { data: googleEvents, isFetching: googleIsFetching } =
    useGoogleCalendarEvents(selectedDay)
  const queriesResults = useAppleEvents(selectedDay)
  const appleIsFetching = queriesResults.some((result) => result.isFetching)
  const appleCalendarEvents = flatMapCalendarEvents(
    queriesResults as UseQueryResult<ExtendedDAVObject[]>[],
    selectedDay
  )

  const combinedEvents = [
    ...currentEventsToday,
    ...(googleEvents?.filter((event) => !event.allDay) ?? []),
    ...appleCalendarEvents
      .flat()
      .filter((event): event is CalendarEvent => !event?.allDay),
  ]

  // Filter out events that have a description that starts with "NPTB:", these are timeblocks created by NotePlan
  const groupedEvents = getGroupedEvents(
    combinedEvents.filter((event: CalendarEvent | null | undefined) => {
      return event && !event.description?.startsWith('NPTB:')
    })
  )

  const eventElements = groupedEvents
    .filter((group) => group.length > 0)
    .map((group) => {
      if (group.length === 1) {
        return (
          <SingleEvent
            key={`event-${group[0].id}`}
            event={group[0]}
            segmentHeight={segmentHeight}
            setCalendarEventDrag={setCalendarEventDrag}
            selectedDay={selectedDay}
          />
        )
      }
      return (
        <MultipleEvents
          key={`event-group-${group[0].startDate.toISOString()}`}
          events={group}
          segmentHeight={segmentHeight}
          setCalendarEventDrag={setCalendarEventDrag}
          selectedDay={selectedDay}
        />
      )
    })

  if (placeholderEvent) {
    const { eventHeight, offsetPxTop } = calculateOffsetAndHeight(
      placeholderEvent,
      segmentHeight,
      selectedDay
    )

    if (!isNaN(eventHeight)) {
      eventElements.push(
        <li
          key='event-placeholder'
          id='event-placeholder'
          className='col-start-1 flex rounded-lg border-2 border-orange-500'
          style={{
            gridRow: placeholderEvent.position,
            transform: `translateY(${offsetPxTop}px)`,
            height: eventHeight - 2,
          }}
        />
      )
    }
  }

  if (googleIsFetching || appleIsFetching) {
    eventElements.push(
      <div key='loading' className='sticky top-0 pr-4 text-right'>
        <i className='fa-solid fa-circle-notch fa-spin fa-xs text-gray-300 dark:text-gray-600' />
      </div>
    )
  }

  return <>{eventElements}</>
}
