import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useCachedNotesQueryClient } from '../providers/CachedNotesProvider'
import { cacheKeys } from '../utils/queryKeyFactory'

export type SigninRequestParams = {
  userid: string
  username: string
  password: string
}

type Response = { success: boolean } | { error: string }

const backendClient = axios.create({
  baseURL: '/api/apple',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function useAppleCalDAVSignin() {
  const cachedNotesQueryClient = useCachedNotesQueryClient()
  return useMutation<Response, Error, SigninRequestParams>({
    mutationFn: async (params: SigninRequestParams) => {
      const response = await backendClient.post<Response>('signin', params)
      return response.data
    },
    onSuccess: (_data: Response, variables: SigninRequestParams) => {
      void cachedNotesQueryClient?.invalidateQueries({
        queryKey: cacheKeys.appleCalendars(variables.userid),
      })
    },
  })
}

type SignoutRequestParams = {
  userid: string
}

export function useAppleCalDAVSignout() {
  const cachedNotesQueryClient = useCachedNotesQueryClient()
  return useMutation<Response, Error, SignoutRequestParams>({
    mutationFn: async (params: SignoutRequestParams) => {
      const response = await backendClient.post<Response>('signout', params)
      return response.data
    },
    onSuccess: (_data: Response, variables: SignoutRequestParams) => {
      cachedNotesQueryClient?.removeQueries({
        queryKey: cacheKeys.appleCalendars(variables.userid),
      })
    },
  })
}
