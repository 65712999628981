import {
  Attachment,
  Note,
  SourceDatabase,
  convertAttachmentToFiles,
  readTagsFromContent,
} from '../../utils/syncUtils'
import { supabase } from '../SupabaseClient'
import { SupabaseNote } from './types'

export async function loadNotesFrom(
  currentUserId: string,
  records: SupabaseNote[]
): Promise<Map<string, Note>> {
  const promises = records.map(async (record) => {
    return [record.id, await loadNoteFrom(currentUserId, record, true)] as [
      string,
      Note,
    ]
  })

  const notesTuples = await Promise.all(promises)
  return new Map<string, Note>(notesTuples)
}

// Converts the given data into a note object, also fetches the download links for attachments on the way
export async function loadNoteFrom(
  currentUserId: string,
  record: SupabaseNote,
  isStub = false
): Promise<Note> {
  const note: Note = {
    recordName: record.id,
    content: record.decrypted_content_encrypted ?? record.content, // Use the decrypted content
    noteType: record.note_type,
    title: record.decrypted_title_encrypted ?? record.title, // Use the decrypted title
    filename: record.filename,
    fileModifiedAt: new Date(record.modified_at),
    uploadedAttachments: record.attachments,
    recordChangeTag: record.change_tag, // If we don't apply a change tag, the update will be filtered out in the TipTapEditor
    parent: record.parent,
    isFolder: record.is_dir,
    isShared: record.user_id != currentUserId, // So we know it's not our note but some that's shared with us
    source: SourceDatabase.SUPABASE,
    owner: record.user_id,
    admins: record.has_shared_with_admin_role ?? [],
  }

  note.tags = readTagsFromContent(note)
  note.attachments = convertAttachments(record.attachments)
  if (!isStub) {
    // Don't load the urls of the attachments when we load all stubs, this just slows down things and the urls are anyways stale in a minute, rather rely on reloading the note when it's opened
    await updateAttachmentUrls(note)
  }

  return note
}

export async function updateAttachmentUrls(note: Note): Promise<boolean> {
  if (!note.attachments || note.attachments.length === 0) {
    return false
  }
  const attachments = JSON.parse(note.attachments)

  if (attachments && attachments.length > 0) {
    const filenames = attachments.map((jsonAsset: string) => {
      const asset = JSON.parse(jsonAsset) as Attachment
      return asset.filename
    })

    try {
      const urls = await getAttachmentURLs(note.recordName, filenames)
      // eslint-disable-next-line no-console
      console.debug('Got attachment urls:', urls[0])
      if (urls) {
        const downloadedAttachments = urls.map((url, index) => {
          const asset = JSON.parse(attachments[index]) as Attachment
          return JSON.stringify({
            title: asset.title,
            filename: asset.filename,
            url: url,
          })
        })
        note.attachments = JSON.stringify(downloadedAttachments)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching attachment urls:', error)
      return false
    }
  }
  return true
}

export async function getAttachmentURLs(
  noteId: string,
  paths: string[]
): Promise<string[] | null> {
  const { data, error } = await supabase.storage
    .from('note_attachments')
    .createSignedUrls(
      paths.map((path) => `${noteId}/${path}`),
      60
    )
  if (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching attachment urls:', error)
    throw error
  }
  return data.map((d) => d.signedUrl)
}

function convertAttachments(attachments: Attachment[]): string | undefined {
  const downloadedAttachments: string[] = []

  if (attachments && attachments.length > 0) {
    for (const asset of attachments) {
      try {
        downloadedAttachments.push(
          JSON.stringify({
            title: asset.title,
            filename: asset.filename,
            url: undefined,
          })
        )
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error converting attachment:', error)
      }
    }

    return JSON.stringify(downloadedAttachments)
  }
}

export async function updateAttachments(
  noteID: string,
  uploadedAttachments: Attachment[],
  newAttachments: string[] | undefined
): Promise<Attachment[]> {
  const savedAttachments: Attachment[] = []

  // First convert the urls into files
  const newAttachmentFiles: Attachment[] =
    await convertAttachmentToFiles(newAttachments)

  // Add the previously uploaded attachments
  if (uploadedAttachments) {
    savedAttachments.push(...uploadedAttachments)
  }

  // Find attachments that are not under the new attachments, but which we had uploaded before. We need to delete them now
  const attachmentsToDelete = savedAttachments.filter(
    (attachment) =>
      !newAttachmentFiles.some(
        (newAsset: Attachment) => newAsset.filename === attachment.filename
      )
  )

  // Delete attachments that are in the database list of attachments, but not inside the note
  for (const attachment of attachmentsToDelete) {
    await deleteAttachment(noteID, attachment.filename)
    savedAttachments.splice(savedAttachments.indexOf(attachment), 1)
  }

  // Upload the remaining new attachments
  for (const asset of newAttachmentFiles) {
    // Prevent attachment from being reuploaded
    if (
      !savedAttachments.find(
        (attachment) => attachment.filename === asset.filename
      )
    ) {
      // Upload the attachment if it doesn't exist yet in the list.
      await uploadAttachment(noteID, asset.file)
      savedAttachments.push({
        title: asset.title,
        filename: asset.filename,
      })
    }
  }

  return savedAttachments
}

async function uploadAttachment(noteID: string, file: File) {
  if (!noteID || !file) {
    return null
  }

  // Check if the file is an image and if it needs resizing
  if (file.type.startsWith('image/')) {
    // Attempt to reduce the file size by resizing and compressing the image
    try {
      const resizedFile = await resizeImage(file)
      file = resizedFile || file // Use the resized image if available
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to resize image: ', error)
    }
  }

  // Convert the type of the file into an extension
  const { error: uploadError } = await supabase.storage
    .from('note_attachments')
    .upload(noteID + '/' + file.name, file)

  if (uploadError) {
    // eslint-disable-next-line no-console
    console.error(uploadError)

    if (uploadError.statusCode === '409') {
      return
    } // Just duplicate, i.e. it's already uploaded
    throw uploadError
  }
}

async function deleteAttachment(
  noteID: string,
  filename: string
): Promise<boolean> {
  const { error } = await supabase.storage
    .from('note_attachments')
    .remove([noteID + '/' + filename])

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }

  return true
}

async function resizeImage(file: File): Promise<File | null> {
  const MAX_WIDTH = 1600 // Max width for the image
  const MAX_HEIGHT = 2000 // Max height for the image
  const QUALITY = 0.5 // Reduced quality for the image compression to save space while keeping alpha layer

  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      let width = image.width
      let height = image.height

      // Calculate the new dimensions
      if (width > height) {
        if (width > MAX_WIDTH) {
          height = Math.round(height * (MAX_WIDTH / width))
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width = Math.round(width * (MAX_HEIGHT / height))
          height = MAX_HEIGHT
        }
      }

      // Create a canvas with the new dimensions
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.imageSmoothingEnabled = true
      ctx.imageSmoothingQuality = 'medium' // Use medium quality for image smoothing
      ctx.drawImage(image, 0, 0, width, height)

      // Convert the canvas to a blob and then to a File
      canvas.toBlob(
        (blob) => {
          if (blob) {
            // Determine the image format to preserve the alpha layer if present
            const imageType = file.type.includes('png')
              ? 'image/png'
              : 'image/jpeg'
            const resizedFile = new File([blob], file.name, {
              type: imageType,
              lastModified: Date.now(),
            })
            resolve(resizedFile)
          } else {
            reject(new Error('Canvas to Blob conversion failed'))
          }
        },
        file.type.includes('png') ? 'image/png' : 'image/jpeg', // Use the original image type
        QUALITY
      )
    }
    image.onerror = reject
    image.src = URL.createObjectURL(file)
  })
}

export async function deleteAttachmentFolder(noteID: string): Promise<boolean> {
  // List all files in the folder
  const { data: files, error: listError } = await supabase.storage
    .from('note_attachments')
    .list(noteID)

  if (listError) {
    // eslint-disable-next-line no-console
    console.error(listError)
    throw listError
  }

  const paths = files.map((file) => noteID + '/' + file.name)

  if (paths.length == 0) {
    return true
  }

  // eslint-disable-next-line no-console
  console.debug(
    '[Supabase] deleteAttachmentFolder noteID, files',
    noteID,
    paths
  )
  const { error: deleteError } = await supabase.storage
    .from('note_attachments')
    .remove(paths)

  if (deleteError) {
    // eslint-disable-next-line no-console
    console.error(deleteError)
    throw deleteError
  }

  // Optionally, delete the folder itself if required
  // Currently, Supabase Storage API does not provide a direct method to delete folders.
  // Folders are virtual in Supabase Storage and are defined by the file paths.
  // If you have removed all files in a folder, the folder does not exist anymore.
  return true
}
