/* eslint-disable @typescript-eslint/no-unsafe-member-access -- ical.js has no types */
/* eslint-disable @typescript-eslint/no-unsafe-call  -- ical.js has no types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- ical.js has no types */
/* eslint-disable import/no-named-as-default-member -- this is how dayjs works */
import type { DAVCalendar, DAVObject } from 'tsdav'
import ICAL from 'ical.js'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { type UseQueryResult } from '@tanstack/react-query'
import { type CalendarEvent } from '../modules/calendar/CalendarEvent'
import { calculateEventPosition } from '../modules/calendar/timeline/utils/event'
import { ExtendedDAVObject } from '../providers/CachedNotesProvider'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * sanitizeCalendarObject is a utility function that cleans up and normalizes
 * the iCalendar data from a DAVObject before parsing it using the ICAL.js library.
 * It addresses issues with incorrect line endings, line folding, and unwanted line
 * breaks before colons, semicolons, and equal signs.
 *
 * @param obj - The DAVObject containing the iCalendar data to be sanitized.
 * @returns The sanitized iCalendar data.
 *
 * NOTE: This function is a workaround for handling improperly formatted iCalendar
 * data. It is recommended to use a well-formed iCalendar data source to avoid
 * the need for these manual corrections. This function may not cover all edge cases
 * and might still cause issues with specific inputs.
 */

export const sanitizeDAVObject = (obj: DAVObject): string => {
  if (typeof obj.data !== 'string') {
    throw new TypeError('Expected obj.data to be a string')
  }

  return obj.data
    .replace(/\r\n/g, '\n')
    .replace(/\r/g, '\n')
    .replace(/\n /g, '')
    .replace(/\n\t/g, '')
    .replace(/\n/g, '\r\n')
    .replace(/(?:[ \t]*\r\n[ \t]*:)/gm, ':')
    .replace(/(?:[ \t]*\r\n[ \t]*;)/gm, ';')
    .replace(/(?:[ \t]*\r\n[ \t]*=)/gm, '=')
}

export function flatMapCalendarEvents(
  queriesResults: UseQueryResult<ExtendedDAVObject[]>[],
  selectedDay: Dayjs
) {
  return queriesResults.flatMap((queryResult) => {
    const davObjects = queryResult.data
    return davObjects
      ?.map((davObject: ExtendedDAVObject): CalendarEvent | null => {
        return convertDAVObjectToCalendarEvent(davObject, selectedDay)
      })
      .filter((event) => event !== null)
  })
}

export function convertDAVObjectToCalendarEvent(
  davObject: ExtendedDAVObject,
  selectedDay: Dayjs
): CalendarEvent | null {
  const jccalData = ICAL.parse(sanitizeDAVObject(davObject))
  const vcalendar = new ICAL.Component(jccalData)
  const vevent = vcalendar.getFirstSubcomponent('vevent')
  const event = new ICAL.Event(vevent)
  const startDate = dayjs(event.startDate.toJSDate() as Date)
  const endDate = dayjs(event.endDate.toJSDate() as Date)
  if (
    endDate.isBefore(selectedDay, 'day') ||
    startDate.isAfter(selectedDay, 'day')
  ) {
    return null
  }
  const isAllDay = event.startDate.isDate && event.endDate.isDate

  const color = davObject.calendarColor

  const calendarEvent: CalendarEvent = {
    contentType: 'event',
    // add random string to force re-render when changing selectedDay
    id: `${event.uid as string}-${Math.random().toString(36).substring(2, 6)}`,
    description: event.description,
    startDate,
    endDate,
    allDay: isAllDay,
    content: event.summary,
    position: calculateEventPosition(startDate, endDate, selectedDay),
    color: color as string | undefined,
    calendarName: davObject.calendarName,
  }

  return calendarEvent
}
